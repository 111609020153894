@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Poppins', sans-serif;
}

body {
	/* background-color: #1e1e1e; */

	/* background: #191c26; */
	background: #12141d;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
input:focus {
	outline: none;
	box-shadow: none;
}
textarea:focus {
	outline: none;
	box-shadow: none;
}

.bg-arrows {
	background: url('../assets/images/background_arrows/arrow1.png') no-repeat,
		url('../assets/images/background_arrows/arrow2.png') no-repeat,
		url('../assets/images/background_arrows/arrow3.png') no-repeat,
		url('../assets/images/background_arrows/arrow4.png') no-repeat;
	background-position: top 0 left -50px, top 0 right -50px, bottom 0 left -50px, bottom 0 right -50px;
	background-size: 400px 500px, 400px 500px, 400px 500px, 400px 500px;
}
.contact-us-bg-arrows {
	background: url('../assets/images/background_arrows/arrow5.png') no-repeat,
		url('../assets/images/background_arrows/arrow6.png') no-repeat;
	background-position: top 0 right -50px, bottom -100px left 0px;
	background-size: 300px 400px, 300px 400px;
}
.reviews-bg-arrows {
	background: url('../assets/images/background_arrows/arrow7.png') no-repeat,
		url('../assets/images/background_arrows/arrow8.png') no-repeat;
	background-position: top 10px right 50px, bottom 10px left 50px;
	background-size: 300px 300px;
}
.footer-bg-arrows {
	background: url('../assets/images/background_arrows/arrow7.png') no-repeat;
	background-position: bottom 10px right 20px;
	background-size: 400px 400px;
}
.reviews-custom {
	background: url('../assets/images/background_arrows/bg-reviews.png') no-repeat;
	background-position: center center;
	background-size: 100% 100%;
}

.up_container {
	position: fixed;
	z-index: 10000000;
	right: 32px;
	bottom: 40px;
	button {
		cursor: pointer;
		width: 45px;
		height: 45px;
		background: #377dff;
		border: none;
		font-size: 14px;
		border-radius: 100%;
		-webkit-border-radius: 100%;
		-moz-border-radius: 100%;
		-ms-border-radius: 100%;
		-o-border-radius: 100%;
		font-weight: 300;
	}
	transition: all 0.5s ease-in-out;
	-webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-ms-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
}
@media screen and (max-width: 1000px) {
	.up_container {
		left: 85%;
		top: 90%;
	}
}
