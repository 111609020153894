.bg-linear {
	background: linear-gradient(266.48deg, #377dff -39.56%, #8504c4 66.99%);
	transition: all 2s ease-in-out;
	-webkit-transition: all 2s ease-in-out;
	-moz-transition: all 2s ease-in-out;
	-ms-transition: all 2s ease-in-out;
	-o-transition: all 2s ease-in-out;
	border: none;
	outline: none;
}
.bg-linear:hover {
	background: linear-gradient(266.48deg, #8504c4 -39.56%, #377dff 66.99%);
}
.play-btn {
	position: relative;
}
.play-btn::before {
	content: '';
	position: absolute;
	z-index: 0;
	left: 50%;
	top: 50%;
	-webkit-transform: translateX(-50%) translateY(-50%);
	-ms-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%);
	display: block;
	width: 45px;
	height: 45px;
	background: rgb(180, 155, 192);
	border-radius: 50%;
	-webkit-animation: pulse-border 1500ms ease-out infinite;
	animation: pulse-border 1500ms ease-out infinite;
}
//
@-webkit-keyframes pulse-border {
	0% {
		-webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
		transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
		transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
		opacity: 0;
	}
}
@keyframes pulse-border {
	0% {
		-webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
		transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
		transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
		opacity: 0;
	}
}

.hero-section-bg-arrows {
	background: url('../../assets/images/background_arrows/hero_section/Group 10308.png') no-repeat,
		url('../../assets/images/background_arrows/hero_section/Group 10309.png') no-repeat,
		url('../../assets/images/background_arrows/hero_section/Group 10310.png') no-repeat,
		url('../../assets/images/background_arrows/hero_section/Group 10311.png') no-repeat,
		url('../../assets/images/background_arrows/hero_section/Polygon 4.png') no-repeat,
		url('../../assets/images/background_arrows/hero_section/Polygon 5.png') no-repeat,
		url('../../assets/images/background_arrows/hero_section/Star 1.png') no-repeat,
		url('../../assets/images/background_arrows/hero_section/Star 2.png') no-repeat;
	background-position: top 0 right 0px, bottom 0 right -50px, bottom 0 left -50px, top 0 left 0px, top 30% left 15%,
		bottom 35% left 21%, top 35% right 15%, bottom 30% right 21%;
	background-size: 300px 300px, 300px 400px, 300px 400px, 300px 400px, 25px 25px, 25px 25px, 25px 25px, 25px 25px;
}

@media (max-width: 900px) {
	.bg-img {
		background-size: 100%;
	}
	.hero-section-bg-arrows {
		background: url('../../assets/images/background_arrows/hero_section/Group 10308.png') no-repeat,
			url('../../assets/images/background_arrows/hero_section/Group 10309.png') no-repeat,
			url('../../assets/images/background_arrows/hero_section/Polygon 4.png') no-repeat,
			url('../../assets/images/background_arrows/hero_section/Star 1.png') no-repeat;
		background-position: bottom 0 right -50px, top 0 left 0px, top 35% left 21%, bottom 30% right 21%;
		background-size: 300px 300px, 300px 400px, 25px 25px, 25px 25px;
	}
}
.scroll {
	position: relative;
}
.scroll::after {
	content: ' ';
	position: absolute;
	width: 2px;
	background-color: #ffffff4a;
	height: 50%;
	top: 20px;
}
