.card-container {
	width: 313px;
	height: 264px;
	position: relative;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	overflow: hidden;
	margin: 2em auto;
}
.img-container {
	width: 313px;
	height: 264px;
	img {
		width: 313px;
		height: 264px;
		overflow: hidden;
		object-fit: cover;
		z-index: 0;
	}
	transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
}
.overlay {
	width: 100%;
	height: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 500;
	background: linear-gradient(182.75deg, rgba(0, 0, 0, 0) 33.64%, #12141d 88.06%);

	overflow: hidden;
}
.content {
	width: 313px;
	height: 264px;
	top: 0;
	left: 0;
	position: absolute;
	opacity: 0;
	transition: all 0.5s ease-in-out;
	-webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-ms-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	z-index: 1000;
	width: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 10px;
	color: white;
	padding-inline: 1em;
	text-align: center;
}
.content button {
	width: 40px;
	height: 40px;
	border-radius: 100%;
	background: #fff;
	-webkit-border-radius: 100%;
	-moz-border-radius: 100%;
	-ms-border-radius: 100%;
	-o-border-radius: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.card-container:hover {
	.content {
		opacity: 1;
	}
	.img-container {
		filter: brightness(0.5);
		-webkit-filter: brightness(0.5);
	}
}
